import React from "react";
import { FaRegHandPeace } from "react-icons/fa";

export default function Parrainage() {
  return (
    <section className="bg-gray-100 dark:bg-neutral-900 text-gray-800 dark:text-gray-200 py-8">
      <div className="container mx-auto flex flex-col lg:flex-row items-center gap-8 px-4">
        {/* Texte de parrainage */}
        <div className="lg:w-1/2 flex flex-col justify-center text-center lg:text-left">
          <h2 className="text-3xl font-bold mb-4">
            Parrainez un club et bénéficiez d'une réduction !
          </h2>
          <p className="text-lg font-light mb-6">
            Invitez d'autres clubs à rejoindre notre plateforme et recevez une
            <span className="font-semibold text-blue-600"> réduction de 5%</span> sur votre prochaine facture annuelle pour tout nouveau club parrainé qui s'abonne. (Offre pour les clients actuels, limité à une réduction de 5% par an)
          </p>
          <div className="flex flex-col lg:flex-row items-center lg:items-start gap-4">
            <div className="flex items-center mt-2 text-blue-600 text-lg">
              <FaRegHandPeace className="mr-2" />
              Merci de contribuer à notre communauté !
            </div>
          </div>
        </div>

        {/* Image du parrainage */}
        <div className="lg:w-1/2 w-full h-64 lg:h-auto flex justify-center items-center">
          {/* Image responsive */}
          <img 
            src="/images/parrainage.png" 
            alt="Parrainage de clubs" 
            className="w-full h-full object-cover"
          />
          
        </div>
      </div>
    </section>
  );
}
