import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsArrowLeftShort, BsChevronDown, BsCalendar2DateFill } from "react-icons/bs";
import { IoIosNotifications } from "react-icons/io";
import { MdLogout, MdDashboard, MdOutlineMessage } from "react-icons/md";
import { FaUsers, FaCalendar, FaCreditCard, FaBook, FaTasks, FaCartPlus, FaEuroSign, FaStar, FaCalendarCheck  } from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";
import { BsCalendar2RangeFill } from "react-icons/bs";
import { MdSettingsApplications } from "react-icons/md";
import { SiGoogleforms } from "react-icons/si";
import { RiMailForbidFill } from "react-icons/ri";
import { useAsideMenu } from "./../../contexts/AsideMenuContext";
import { useMessage } from "./../../contexts/MessageContext";
import { useNotification } from "../../contexts/NotificationContext";
import { useContactForm } from "../../contexts/ContactFormContext";
import { useTask } from "../../contexts/TaskContext";
import { getFavoriteAssociations } from "./../../services/associationsServices";
import "./AsideMenu.css";

const AsideMenu = ({ role_id, userInfo }) => {
  const { isOpen, toggleMenu } = useAsideMenu();
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [favoriteAssociations, setFavoriteAssociations] = useState([]);
  const { unreadMessageCount } = useMessage();
  const { unreadCount } = useNotification();
  const { unreadCountContactForm } = useContactForm();
  const { tasksNotDoneCount } = useTask();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFavoriteAssociations = async () => {
      try {
        const associations = await getFavoriteAssociations();
        setFavoriteAssociations(associations);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des associations favorites:",
          error
        );
      }
    };

    fetchFavoriteAssociations();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    navigate("/"); 
    window.location.reload(); 
  };

  const handleProfileClick = () => {
    navigate("/appli/profil");
  };

  const menus = {
    1: [
      // Administrateur
      { title: "Dashboard", path: "/appli/dashboard-admin", icon: <MdDashboard /> },
      { title: "Notifications", path: "/appli/notifications", icon: <IoIosNotifications />, badge: unreadCount},
      {
        title: "Messages",
        path: "/appli/messaging",
        icon: <FaMessage />,
        badge: unreadMessageCount,
      },
      {
        title: "Contact Formulaire",
        path: "/appli/contact-form",
        icon: <MdOutlineMessage />,
        badge: unreadCountContactForm,
      },
      {
        title: "Taches",
        path: "/appli/liste-taches",
        icon: <FaTasks />,
        badge: tasksNotDoneCount,
      },
      { title: "Listing Utilisateurs", path: "/appli/listing-users", icon: <FaUsers /> },
      { title: "Listing Associations", path: "/appli/listing-associations", icon: <FaStar />, submenu: true },
      { title: "Listing Rendez-vous", path: "/appli/listing-rendez-vous", icon: <FaCalendarCheck  /> },
      { title: "Echéances", path: "/appli/echeances", icon: <BsCalendar2DateFill  /> },
      { title: "Produits", path: "/appli/products", icon: <FaCartPlus /> },
      { title: "Abonnements", path: "/appli/subscriptions", icon: <BsCalendar2RangeFill /> },
      { title: "Dépenses Pro", path: "/appli/depenses", icon: <FaEuroSign /> },
      { title: "Règlements", path: "/appli/reglements", icon: <FaCreditCard /> },
      { title: "Desinscriptions", path: "/appli/desinscriptions-admin", icon: <RiMailForbidFill /> },
      { title: "Déconnexion", path: "#", icon: <MdLogout />, action: handleLogout , spacing: true},
    ],
    2: [
      // Utilisateur
      { title: "Tutoriel MonAppliClub", path: "/appli/tutoriel", icon: <FaBook /> },
      { title: "Notifications", path: "/appli/notifications", icon: <IoIosNotifications />, badge: unreadCount },
      { title: "Messages", path: "/appli/messaging", icon: <FaMessage />, badge: unreadMessageCount },
      { title: "Mon Association", path: "/appli/mon-association", icon: <FaStar /> },
      { title: "Formules Tarifaires", path: "/appli/tarifs", icon: <FaEuroSign /> },
      { title: "Déconnexion", path: "#", icon: <MdLogout />, action: handleLogout , spacing: true},
    ],
    3: [
      // Client
      { title: "Tutoriel MonAppliClub", path: "/appli/tutoriel", icon: <FaBook /> },
      { title: "Notifications", path: "/appli/notifications", icon: <IoIosNotifications />, badge: unreadCount },
      { title: "Messages", path: "/appli/messaging", icon: <FaMessage />, badge: unreadMessageCount },
      { title: "Mon Association", path: "/appli/mon-association", icon: <FaStar /> },
      { title: "Formules Tarifaires", path: "/appli/tarifs", icon: <FaEuroSign /> },
      { title: "Abonnements", path: "/appli/subscriptions", icon: <BsCalendar2RangeFill /> },
      { title: "Déconnexion", path: "#", icon: <MdLogout />, action: handleLogout, spacing: true },
    ],
  };

  return (
    <aside className={`bg-gray-700 h-screen flex flex-col justify-between fixed z-10000 ${isOpen ? "w-72" : "w-20"} duration-300`}>
      <div className="flex-1 overflow-y-auto overflow-x-hidden hide-scrollbar p-5 pt-8">
        <div className="text-2xl font-bold mb-6 relative">
          <div className="text-4xl font-semibold font-passionOne">
            <Link to="/">
              <button className="text-white focus:outline-none">
                {isOpen ? (
                  <>
                    Mon
                    <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600">
                      Appli
                    </span>
                    Club
                  </>
                ) : (
                  <MdSettingsApplications className={`text-yellow-400 hover:text-orange-600 text-4xl duration-500 ${isOpen && "rotate-[360deg]"}`} />
                )}
              </button>
            </Link>
          </div>
          <BsArrowLeftShort
            className={`bg-white text-gray-700 text-3xl rounded-full absolute -right-5 top-1 border border-gray-700 cursor-pointer ${!isOpen && "rotate-180"}`}
            style={{ zIndex: 999 }}
            onClick={toggleMenu}
          />
        </div>
        <nav>
          <ul className="pt-2">
            {menus[role_id].map((menu, index) => (
              <React.Fragment key={index}>
                <li
                  className={`relative flex items-center py-2 text-gray-300 text-sm gap-x-4 cursor-pointer p-2 hover:bg-light-white rounded-md ${
                    menu.spacing ? "mt-9" : "mt-2"
                  }`}
                  onClick={menu.action ? menu.action : null}
                >
                  <Link to={menu.path} className="flex items-center">
                    <div className="relative">
                      <span className="text-2xl block float-left">{menu.icon}</span>
                      {menu.badge > 0 && (
                        <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-600 text-white text-xs rounded-full px-2 py-0.5">
                          {menu.badge}
                        </span>
                      )}
                    </div>
                    <span className={`ml-2 text-base font-thin flex-1 text-white duration-200 ${!isOpen && "hidden"}`}>
                      {menu.title}
                    </span>
                  </Link>
                  {menu.submenu && (
                    <BsChevronDown
                      className={`text-white cursor-pointer transform duration-300 ${submenuOpen ? "rotate-180" : ""}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSubmenuOpen(!submenuOpen);
                      }}
                    />
                  )}
                </li>
                {menu.submenu && (
                  <ul className={`pl-8 transition-all duration-300 overflow-hidden ${submenuOpen ? "max-h-screen" : "max-h-0"}`}>
                    {favoriteAssociations.map((association) => (
                      <li key={association.id} className="text-gray-300 py-1 text-sm hover:text-white flex items-center">
                        <span className="mr-2">⭐</span>
                        <Link to={`/appli/association-${association.id}`}>{association.nom_asso}</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </React.Fragment>
            ))}
          </ul>
        </nav>
      </div>
      <div
        className={`flex items-center ${isOpen ? "bg-gray-800 p-2" : "justify-center"} text-white transition-all duration-300 pb-2 cursor-pointer`}
        onClick={handleProfileClick}
      >
        <img
          src={userInfo.photoURL || "/img/user_avatar.jpg"}
          alt="Avatar"
          className={`h-10 w-10 rounded-full object-cover ${isOpen ? "mr-3" : ""}`}
        />
        <div className={`${!isOpen && "hidden"}`}>
          <h2 className="text-lg font-semibold">
            {userInfo.nom} {userInfo.prenom}
          </h2>
          <p className="text-sm">
            {userInfo.role_id === 1
              ? "Administrateur"
              : userInfo.role_id === 2
              ? "Utilisateur"
              : "Client"}
          </p>
        </div>
      </div>
    </aside>
  );
};

export default AsideMenu;
