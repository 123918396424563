import React, { useState, useEffect } from 'react';
import ButtonEdit from '../../components/Button/ButtonEdit';
import ButtonDelete from '../../components/Button/ButtonDelete';
import ButtonTel from '../../components/Button/ButtonTel';
import ButtonMail from '../../components/Button/ButtonMail';
import Button from '../../components/Button/Button';
import ButtonVerif from '../../components/Button/ButtonVerif';
import { deletePartenaire } from '../../services/partenaireskataServices';
import EditPartenaireModal from './EditPartenaireModal';
import { toast } from 'react-toastify';
import { MdEmail, MdPhone  } from 'react-icons/md'

const PartenaireKataCard = ({ partenaire, refreshPartenairesList }) => {
  const defaultImage = "/img/default_image.jpg";
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [showAuthForm, setShowAuthForm] = useState(false);
  const [authError, setAuthError] = useState('');

  const handleDeletePartenaire = async () => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce partenaire ?")) {
      try {
        await deletePartenaire(partenaire.id);
        toast.success("Partenaire supprimé avec succès.");
        refreshPartenairesList(); // Mise à jour après suppression
      } catch (error) {
        console.error("Erreur lors de la suppression du partenaire:", error);
        toast.error("Erreur lors de la suppression du partenaire.");
      }
    }
  };

  const handleAuthSubmit = async (e) => {
    e.preventDefault();
    if (emailInput === partenaire.mail && passwordInput === partenaire.password) {
      setIsVerified(true);
      setShowAuthForm(false);
      setAuthError('');
      toast.success("Vérification réussie !");
    } else {
      setAuthError("Mail ou mot de passe incorrect.");
    }
  };

  const toggleAuthForm = () => {
    setShowAuthForm(!showAuthForm);
    setAuthError(''); // Réinitialise le message d'erreur si on referme le formulaire
  };

  // Utilisez refreshPartenairesList ou créez une fonction pour rafraîchir la liste
  const refreshList = () => {
    if (refreshPartenairesList) {
      refreshPartenairesList(); // Appelle la fonction transmise par le parent
    } else {
      console.log("refreshList n'est pas défini dans PartenaireKataCard");
    }
  };

  // Mapping des images des grades
  const gradeImages = {
    "1er Dan": "/images/belt/ceinture_judo_1D.png",
    "2ème Dan": "/images/belt/ceinture_judo_2D.png",
    "3ème Dan": "/images/belt/ceinture_judo_3D.png",
    "4ème Dan": "/images/belt/ceinture_judo_4D.png",
    "5ème Dan": "/images/belt/ceinture_judo_5D.png",
    "6ème Dan": "/images/belt/ceinture_judo_rouge_blanche.png",
    "7ème Dan": "/images/belt/ceinture_judo_rouge_blanche.png",
    "8ème Dan": "/images/belt/ceinture_judo_rouge.png",
    "9ème Dan": "/images/belt/ceinture_judo_rouge.png",
    "10ème Dan": "/images/belt/ceinture_judo_rouge.png",
  };

  const ButtonTel = ({ tel }) => {
  const handleCall = () => {
    window.open(`tel:${tel}`, "_blank");
  };

  return (
    <Button
      text=""
      onClick={handleCall}
      icon={MdPhone}
      hoverLabel="Appeler"
    />
  );
};

const ButtonMail = ({ mail }) => {
  const handleEmail = () => {
    window.open(`mailto:${mail}`, "_blank");
  };

  return (
    <Button
      text=""
      onClick={handleEmail}
      icon={MdEmail}
      hoverLabel="Envoyer un email"
    />
  );
};



return (
    <div className="flex flex-col shadow-md bg-white dark:bg-slate-700 rounded-lg overflow-hidden w-full border border-gray-800">
      <div className="flex flex-col justify-center items-center py-4 bg-gray-100 dark:bg-gray-600">
        <img
          src={gradeImages[partenaire.passage_grade] || defaultImage}
          alt={partenaire.passage_grade}
          className="object-contain w-48 h-20"
        />
        <p className="text-gray-700 dark:text-gray-200 text-xs font-semibold">
          {partenaire.passage_grade}
        </p>
      </div>
      <div className="p-4 text-center">
        <h3 className="text-lg font-semibold text-gray-700 dark:text-white">
          {partenaire.nom} {partenaire.prenom}
        </h3>
        <p className="text-gray-500 dark:text-gray-300 text-sm">{partenaire.club}</p>
          <p className="text-gray-400 dark:text-gray-400 italic text-sm">
            {partenaire.departement} - {partenaire.region}
          </p>
        <p
          className="text-gray-700 dark:text-gray-200 mt-2 text-sm"
          dangerouslySetInnerHTML={{ __html: partenaire.content }}
        ></p>
        <div className="flex justify-center items-center gap-4 mt-4">
          {partenaire.tel && <ButtonTel tel={partenaire.tel} />}
          {partenaire.mail && <ButtonMail mail={partenaire.mail} />}
          {isVerified ? (
            <>
              <ButtonEdit onClick={() => setEditModalOpen(true)} />
              <ButtonDelete onClick={handleDeletePartenaire} />
            </>
          ) : (
            <ButtonVerif onClick={toggleAuthForm} />
          )}
        </div>
      </div>

      {/* Formulaire d'authentification */}
      {showAuthForm && (
        <div className="p-4 bg-gray-200 dark:bg-gray-800 rounded-b-lg">
          <form onSubmit={handleAuthSubmit} className="flex flex-col gap-2">
            <input
              type="email"
              placeholder="Email"
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              className="p-2 rounded border"
              required
            />
            <input
              type="password"
              placeholder="Mot de passe"
              value={passwordInput}
              onChange={(e) => setPasswordInput(e.target.value)}
              className="p-2 rounded border"
              required
            />
            <button
              type="submit"
              className="bg-blue-500 text-white p-2 rounded mt-2"
            >
              Vérifier
            </button>
            {authError && (
              <p className="text-red-500 text-sm mt-2">{authError}</p>
            )}
          </form>
        </div>
      )}

      {editModalOpen && (
        <EditPartenaireModal
          partenaire={partenaire}
          isOpen={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          refreshList={refreshPartenairesList}
        />
      )}
    </div>
  );
};

export default PartenaireKataCard;